<template>
  <div :class="cardClass">
    <va-card
      :title="name"
      :card-badges="cardBadges"
    >
      <template slot="actions">
        <!-- <va-button flat small icon="at-icon l_settings1" @click="settingsModal = !settingsModal"/> -->
        <router-link v-if="context === 'room'" :to="{ name: context+'-details', params: { roomId: id, displayName: name } }">
          <va-button flat small icon="at-icon card_plus"/>
        </router-link>
        <template v-if="context === 'irrigation'">
          <div class="global-actions">
            <va-button flat small icon="at-icon l_player_pause" @click="setAction('pause')"/>
            <va-button flat small icon="at-icon l_player_stop" @click="setAction('stop')"/>
            <va-button flat small icon="at-icon l_player_play" @click="setAction('play')"/>
          </div>
          <router-link :to="{ name: context+'-details', params: { irrigationLineId: id, displayName: name } }">
            <va-button flat small icon="at-icon card_plus"/>
          </router-link>
        </template>
      </template>

      <!-- Current conditions -->
      <div class="row">
        <div class="flex xs12">
          <div class="row">
            <div class="flex xs6" v-for="(setting, name) in settingsNow" :key="name">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top">
                      <va-icon :name="getDataIcon(name)"></va-icon>
                      <span v-html="unitFormat(setting.val, setting.units)"></span>
                    </div>
                    <div class="content__main__bottom">
                      {{ $t(`data.${name}`) }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Room Occupation -->
            <div v-if="occupation && context === 'room'" class="flex xs6">
              <template>
                <div class="content rectangle">
                  <div class="content__main">
                    <div class="content__main__top">
                      <div class="d-flex justify--center">
                        <div>
                          <va-progress-circle :value="occupation">
                            {{ occupation }} <sub>%</sub></va-progress-circle>
                        </div>
                      </div>
                    </div>
                    <div class="content__main__bottom">
                      {{ $t(`growing.roomOccupation`)}}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Irrigation Progress -->
            <div v-if="progress && context === 'irrigation'" class="flex xs12">
              <template>
                <div class="content">
                  <div class="content__main">
                    <va-progress-bar v-model="progress.percent">
                      <span :style="{visibility: progress.state !== 'Not running' ? 'visible' : 'hidden'}">{{ Math.round(progress.percent) }}%</span>
                    </va-progress-bar>
                    <div class="content__main__bottom">
                      <span v-if="progress.state !== 'Not running'">{{ $t('irrigation.progress') }} {{ (progress.percent / progress.total) ? (Math.round(progress.percent * progress.total) / 100) : 0 }}/{{ Math.round(progress.total * 100) / 100 }} {{ progress.units}}</span>
                      <span v-else>{{ $t('irrigation.progress') }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </va-card>

    <va-modal
      v-model="settingsModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="settingsModal"
    >

      <at-settings-form
        :title="`${$t('growing.settings')}`"
        :subtitle="name"
        :settings-array="settings"
      />

      <template slot="actions">
        <div class="row">
          <div class="flex xs4">
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="cancel()" flat color="gray">
              {{  $t('modal.cancel') }}
            </va-button>
            <va-button @click="confirm()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

    </va-modal>
  </div>
</template>

<script>
import { styleHelpers } from '@/services/atmosphere-ui'
import AtSettingsForm from './../../at-settings-form/AtSettingsForm'

export default {
  name: 'growing-cg-card',
  data () {
    return {
      settingsModal: false,
    }
  },
  props: {
    cardClass: {
      type: String,
      default: 'flex xs12',
    },
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    occupation: {
      type: Number,
      default: null,
    },
    progress: {
      type: Object,
      default: null,
    },
    settingsNow: {
      type: Object,
    },
    context: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      default () {
        return {}
      },
    },
    cardBadges: {
      type: Array,
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
  },
  methods: {
    getDataIcon (dataName) {
      return styleHelpers.growingIcon(this.context, dataName)
    },
    unitFormat (value, units) {
      return styleHelpers.unitFormat(value, units)
    },
    setAction (selectedAction) {
      this.$emit('setAction', {
        lineId: this.id,
        action: selectedAction,
      })
    },
    cancel () {
      this.$refs.settingsModal.cancel()
    },
    confirm () {
      this.$emit('saveControlGroupLineSettings', this.id)
      this.$refs.settingsModal.ok()
    },
  },
  components: {
    AtSettingsForm,
  },
}
</script>

<style lang="scss">
.global-actions {
  .va-button {
    margin-right: 0 !important;

    &:last-child {
      margin-right: 1rem !important;
    }

    .at-icon {
      font-size: 1.3rem !important;
    }
  }
}
</style>
